import { DELETE_FLASH_MESSAGE, SET_FLASH_MESSAGE } from './-mutation-types';
import { useFlashMessagesStore } from '~/stores/flash-messages';
import type { FlashMessage } from '~/stores/flash-messages/state';

const Actions = {
    deleteFlashMessage(identifier: string) {
        const flashMessagesStore = useFlashMessagesStore();
        flashMessagesStore[DELETE_FLASH_MESSAGE](identifier);
    },
    setFlashMessage(payload: FlashMessage) {
        const flashMessagesStore = useFlashMessagesStore();
        flashMessagesStore[SET_FLASH_MESSAGE](payload);
    },
};

export default Actions;
