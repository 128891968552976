import type { ConcreteComponent } from 'vue';

export type FlashMessage = {
    path: ConcreteComponent | string;
    properties: { [key: string]: string | number };
};

export type FlashMessagesState = {
    flashMessages: {
        [key: string]: FlashMessage;
    };
}

export const defaultStateData = (): FlashMessagesState => ({
    flashMessages: {},
});

export const DefaultState = (): FlashMessagesState => defaultStateData();

export type State = ReturnType<typeof DefaultState>
