/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './flash-messages/state';
import Getters from './flash-messages/getters';
import Actions from './flash-messages/actions';
import Mutations from './flash-messages/mutations';

export const useFlashMessagesStore = defineStore(
    'flash-messages',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
