import { GET_FLASH_MESSAGES } from './-getter-types';
import { useFlashMessagesStore } from '~/stores/flash-messages';
import type { FlashMessage } from '~/stores/flash-messages/state';

const Getters = {
    [GET_FLASH_MESSAGES]: (): Record<string, FlashMessage> => {
        const flashMessagesStore = useFlashMessagesStore();

        return flashMessagesStore.flashMessages;
    },
};

export default Getters;
